.btn-small {
    height: 42px;
    // min-width: 200px;
    font-size: 16px;
    line-height: 22px;
}

.btn-primary {
    border-radius: 20px !important;
    background-color: #bf914a;
    border-color: #bf914a;
    color: #FFFFFF;

    &:hover, &:active, &:focus {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;

    }
    &:disabled, &:disabled:hover {
        background-color: #E5D3B6;
        border-color: #E5D3B6;
        color: #FFFFFF;
    }
}

.btn-secondary {
    border-radius: 20px !important;
    color: #bf914a;
    border: 1px solid #bf914a; 
    &:hover {
      color: #bf914a;
      border: 1px solid #bf914a; 
      background: transparent;
    }
    &:disabled, &:disabled:hover {
      background: transparent;
    }
  }

.ant-table-column-title {
    color: #A9A9A9;
    font-weight: normal;
    text-transform: uppercase;
}

.products-container {
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

    .search-container {
        margin: 20px;
        width: 25%;
        .ant-input {
            border: 1.5px solid #BFBFBF;
            color: #808080;
        }
    }

    .inactive {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color:#A80F1B;
        background-color: #FEF3F3;
        border: 1px solid #F5A2A6;
    }
    
    .active {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color: #028010 !important;
        background-color: #e6fff1 !important;
        border: 1px solid #78d89f !important;
    }
}

.inactive {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color:#A80F1B;
    background-color: #FEF3F3;
    border: 1px solid #F5A2A6;
}

.active {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color: #028010 !important;
    background-color: #e6fff1 !important;
    border: 1px solid #78d89f !important;
}

.ant-switch-checked {
    background-color: #303030;
}
.status-dropdown{
    &:disabled{
        background-color: #f5f5f5 !important;
        border: 1px solid lightgray !important;
        color: #b7b7b7 !important;
    }
}