.audit-trail-page {
  
  .audit-trail-content-page {
      padding: 20px;

      .audit-trail-title{
        color:'black';
        font-size:28px;
      }

     
      .audit-trail-search {
        width:100%;
      }

  }
}