.inquiry-db-page {
  
  .inquiry-db-content-page {
      padding: 20px;

      .inquiry-db-title{
        color:'black';
        font-size:28px;
      }

     
      .inquiry-db-search {
        width:100%;
      }

  }
}