.distributors-container {
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

    .search-container {
        width: 25%;
        margin-right: 40px;

        .ant-input {
            border: 1.5px solid #BFBFBF;
            color: #808080;
            margin: 8%;
        }
    }

    .inactive {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color:#A80F1B;
        background-color: #FEF3F3;
        border: 1px solid #F5A2A6;
    }
    
    .active {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color: #028010 !important;
        background-color: #e6fff1 !important;
        border: 1px solid #78d89f !important;
    }
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: right;
    white-space: normal;
}

.ant-tabs-bar {

    // width: 20%;

    .ant-tabs-nav {
        color: #714521;
    
        .ant-tabs-tab:hover {
            color: #714521;
        }
    
        .ant-tabs-tab {
            color: #714521;
        }
    
        .ant-tabs-ink-bar {
            background-color: #714521;
        }
    }
}


.inactive {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color:#A80F1B;
    background-color: #FEF3F3;
    border: 1px solid #F5A2A6;
}

.active {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color: #028010 !important;
    background-color: #e6fff1 !important;
    border: 1px solid #78d89f !important;
}

.search-and-button {

    .btn-small {
        height: 42px;
        // min-width: 200px;
        font-size: 16px;
        line-height: 22px;
    }
    
    .btn-primary {
        border-radius: 20px !important;
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;

        &:hover, &:active, &:focus {
            background-color: #bf914a;
            border-color: #bf914a;
            color: #FFFFFF;

        }
        &:disabled, &:disabled:hover {
            background-color: #E5D3B6;
            border-color: #E5D3B6;
            color: #FFFFFF;
        }
    }

}