.faq-container-page {
  
  .faq-content-page {
      padding: 20px;

      .faq-title{
        color:'black';
        font-size:28px;
      }

      .faq-add-new-category {
        color: white;
        background-color: #BF914A;
        height: 40px;
        border-radius: 46px;
        padding: 0 40px;
      }

      .faq-tabs {
        margin-top:20px;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      .faq-header {
        width:100%;

        .faq-header-title{
          font-size: 22px;
          font-weight: 400;
          color: #606060;
          text-transform: capitalize;
        }

        .faq-header-action {
          display: flex;

          h1 {
            color:#7A4521;
            font-size: 16px;
            margin:10px;
            white-space: nowrap;
            line-height: 25px;
            cursor: pointer;
          }

          button {
            margin:10px;
            border-radius: 46px;
            border: 1px solid #7A4521;
            width:213px;

            span {
              color:#7A4521;
              font-weight: 500;
            }
          }
        }
      }

      .faq-search {
        width:100%;

        .faq-sort-by {
           span {
             margin-left: 10px;
             margin-right: 10px;
           }
           .faq-sort-by-input {
            width: 200px;
           }

           
        }
      }

      .faq-draggable-container {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        // transform-origin: 50% 50%;
        // width: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        background: white;
        min-height: 148px;
        // pointer-events: none;

        .faq-draggable {
          // width:100%;
          // padding:24px;
          // display: flex;
          // flex-direction: row-reverse;

           .faq-draggable-switch {
              display: flex;
              justify-content: center;
              margin: 15px 0;
           } 

           .faq-draggable-content {
             margin: 15px 0;
            strong{
              margin:16px 0
            }
            p {
              margin:16px 0
            }
           }

           .faq-draggable-actions {
             margin:15px 0;
             display: flex;
             justify-content: center;

            .ant-select-selection{
              border:none;
              margin:5px;
              color:#808080;
              font-size: 12px;
              
              &:focus {
                box-shadow: none;
                border:none !important;
                outline: none !important;
              }

              &:active {
                box-shadow: none;
                border: none !important;
                outline:none !important;
              }

            }

             .faq-icon {
               margin:15px;
             }
           }
        }
      } 
  }

  .faq-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-top: 1px solid lightgray;
    opacity: 0.5;
    color:lightgray;
    font-weight: 500;
  }
 

  .dragged {
    color:black;
    background-color: white !important;
    margin-left: -20px !important;
    z-index: 999;
  
  }
  
  .placeholder {
    background: #F5F5F5 !important;
    width:100%;
    height:180px ;
  }
}

.faq-modal-new-category{
  
  .ant-modal-content {
    
    .faq-modal-form {

      .faq-view-options{
        label{
          color:#404040;
        }

          .faq-view-options-radio-btn {
            display: inline-block;
            position: relative;
            padding: 0 6px;
            margin: 10px 0 0;
          }
          
          .faq-view-options-radio-btn input[type='radio'] {
            display: none;
          }
          
          .faq-view-options-radio-btn label:before {
            content: " ";
            display: inline-block;
            position: relative;
            top: 5px;
            margin: 0 16px 0 0;
            width: 20px;
            height: 20px;
            border-radius: 11px;
            border: 1px solid lightgray;
            background-color: transparent;
          }
          
          .faq-view-options-radio-btn input[type=radio]:checked + label:after {
            border-radius: 4px;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 6px;
            left: 6px;
            content: " ";
            display: block;
            background:#1F2D3D;
          }
        }

      .faq-modal-cancel-btn {
        border: 0;
        outline: 0;
        box-shadow: none;
        margin: 0 14px;

        &:focus {
          border:0; 
          outline: 0;
          box-shadow: none;
         }
        
        span{
          color:#808080 ;
        }
      }

      .input-line {
        border: 2px solid #DFC8A5;
        border-left: none;
        border-right: none;
        border-top: none;
        outline: 0;
        opacity: 0.8;

        &:focus {
          outline: 0;
          border: 2px solid #DFC8A5;
          border-left: none;
          border-right: none;
          border-top: none;
          box-shadow: none;
        }
      }

      .faq-modal-submit-btn {
        background: #BF914A !important;
        border-color:lightgray !important;
        border-radius: 46px !important;
        width: 198px;
        height: 40px;

        &:focus {
         border: 1px solid lightgray; 
        }

        span{
          color:white;
        }
      }
    }
  
  }
    
}





