.user-types-page {

    .ant-table {
        background: #fff;
    }

    .ant-table-title {
        padding: 24px;
    }

    .active-tag {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        padding: 8px 25px;
        text-transform: uppercase;
        color: #028010;
        background-color: #e6fff1;
        border: 1px solid #78d89f;
    }
    
    .inactive-tag {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        padding: 8px 25px;
        color:#A80F1B;
        text-transform: uppercase;
        background-color: #FEF3F3;
        border: 1px solid #F5A2A6;
    }

    .user-types-container {
        margin-top: 20px;
        border-radius: 15px;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    }
}

.user-roles-modal {

    .ant-form label {
        white-space: nowrap;
    }

    .ant-row .checkboxes .ant-col-12 {
        margin: 10px 0px;
        text-transform: capitalize;
        white-space: nowrap;
    }

    .ant-card-body {
        height: 175px;
    }

    .ant-card-head-title {
        text-transform: capitalize;
    }
}
