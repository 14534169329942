.order-modal-container {

  .ant-row-flex {
    .btn-primary {
      font-weight: 600;
      padding: 0 30px 0 30px;
      -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
      -moz-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
      box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
  
      background-color: #bf914a;
      border-radius: 30px;
      border-color: #bf914a;
      color: #FFFFFF;
      &:hover, &:active, &:focus {
          background-color: #bf914a;
          border-color: #bf914a;
          color: #FFFFFF;
  
      }
      &:disabled, &:disabled:hover {
          background-color: #E5D3B6;
          border-color: #E5D3B6;
          color: #FFFFFF;
      }
    }
    
    .btn-default {
      font-weight: 500;
      color: #7c5c2d;
      border: none;
      &:hover {
        color: #bf914a;
        border: none;
        background: transparent;
      }
      &:disabled, &:disabled:hover {
        background: transparent;
      }
    }
    
    .btn-small {
      height: 42px;
      min-width: 150px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .ant-modal-header {
    padding: 0;
    border: 0;

    .ant-modal-title {
      font-family: "Montserrat";
      font-size: 1.8rem;
      font-weight: 500;
      padding: 30px 0px 0px 30px;
    }
    
  }
  
  .ant-table-body {
    .ant-table-row {
      td {
          padding: 40px 10px 40px 10px;
      }
    }
  }

  .orders-table {
    font-family: "Open Sans";
    font-size: 1.3rem;
  }
  
  .ant-table-thead {
    background-color: #BFBFBF;
    .ant-table-header-column {
      font-weight: 400;
      color: #AFAFAF;
    }
  }

  .search-container {
    margin: 20px;
    input {
      border: 1.5px solid #BFBFBF;
    }
  }
  
  .ant-modal-body {
    padding: 0;
  }
  
  .btn-primary {
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
        padding: 0 30px 0 30px;
    -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
  
    background-color: #bf914a;
    border-radius: 30px;
    border-color: #bf914a;
    color: #FFFFFF;
    &:hover, &:active, &:focus {
      background-color: #bf914a;
      border-color: #bf914a;
      color: #FFFFFF;

    }
    &:disabled, &:disabled:hover {
      background-color: #E5D3B6;
      border-color: #E5D3B6;
      color: #FFFFFF;
    }
  }
  
  .btn-default {
    color: #bf914a;
    border: none;
    &:hover {
      color: #bf914a;
      border: none;
      background: transparent;
    }
    &:disabled, &:disabled:hover {
      background: transparent;
    }
  }
  
  .btn-small {
    height: 42px;
    min-width: 150px;
    font-size: 16px;
    line-height: 22px;
  }
  
  .ant-table-tbody {
    .order-status-tag {
      white-space: nowrap;
      font-size: 12px;
      font-weight: 700;

      .cancelled, .noSap {
        border-radius: 50px;
        padding: 10px 25px;
        min-width: 100px;
        color:#A80F1B;
        background-color: #FEF3F3;
        border: 1px solid #F5A2A6;
      }

      .active {
        border-radius: 50px;
        padding: 10px 25px;
        min-width: 100px;
        color: #028010;
        background-color: #e6fff1;
        border: 1px solid #78d89f;
      }

      .invoiced {
        border-radius: 50px;
        padding: 10px 25px;
        min-width: 100px;
        color: #028010;
        background-color: #e6fff1;
        border: 1px solid #78d89f;
      }

      .forLoading, .produced, .forProduction {
        border-radius: 50px;
        padding: 10px 20px;
        min-width: 100px;

        color: #d66f0e;
        background-color: #ffefe0;
        border: 1px solid #ffc590;
      }
    }
  }
}

.replacement-modal {

  .ant-modal-header {
    padding: 0px 0px 30px 30px;

    .ant-modal-title {
      font-family: "Montserrat";
      font-size: 1.8rem;
      font-weight: 500;
      padding: 30px 0px 0px 30px;
    }
    
  }

  .btn-default {
    color: #bf914a;
    border: none;
    &:hover {
      color: #bf914a;
      border: none;
      background: transparent;
    }
    &:disabled, &:disabled:hover {
      background: transparent;
    }
  }
  
  .btn-small {
    height: 42px;
    min-width: 150px;
    font-size: 16px;
    line-height: 22px;
  }

  .btn-primary {
    font-weight: 600;
    padding: 0 30px 0 30px;
    -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
  
    background-color: #bf914a;
    border-radius: 30px;
    border-color: #bf914a;
    color: #FFFFFF;
    &:hover, &:active, &:focus {
      background-color: #bf914a;
      border-color: #bf914a;
      color: #FFFFFF;

    }
    &:disabled, &:disabled:hover {
      background-color: #E5D3B6;
      border-color: #E5D3B6;
      color: #FFFFFF;
    }
  }
    
  .comments-content {
    margin: 30px 50px 30px 50px;
    
    h3 {
      font-family: "Open Sans";
      color: #808080;
      font-weight: 400;
      margin: 0 0 20px 10px;
    }
  
    .comments-input {
      border: none;
      border-bottom: 2px solid #EFE4D2;
      font-family: "Montserrat";
      font-size: 1.05rem;
      line-height: 25px;
      color: #404040;
      padding-bottom: 15px;
    }
  }

  
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800');