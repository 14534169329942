.orders-container {

    .order-table-component {
        overflow: auto;
    }

    background: '#FCFDFF';
    // padding: 20px;
    .ant-table-body {
        .ant-table-row {
            td {
                padding: 50px 10px 50px 10px;
            }
        }
    }
    .orders-header {
        margin: 10px 0;

        h1{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 38px;
            color: #1F2D3D;
        }
    }
    .orders-filter {
        margin: 24px;
        
        .col-filter {
            display: flex;
            flex-direction: column;

            label {
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #6A6A6A;
                margin:10px 0;
            }
        }

        .col-filter-reset {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
            
            label {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #808080;
                margin: 10px 0;
            }
        }
    }
    .orders-content {
        background: #FFFFFF;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        margin: 42px 0;

        .content-filter {
            display: flex;
            margin:24px;
        }

        .ant-table {
          tr {
            cursor: pointer;
          }
        }
    }
    .radio {
        display: inline-block;
        position: relative;
        padding-left: 50px;
        padding-top: 4px;
        margin-bottom: 12px;
        margin-right: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    /* Hide the browser's default checkbox */
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid #1F2D3D;
    }
    
    /* On mouse-over, add a grey background color */
    .radio:hover input ~ .checkmark {
        background-color: #ccc;
    }
    
    /* When the checkbox is checked, add a blue background */
    .radio input:checked ~ .checkmark {
        background-color: #1F2D3D;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    /* Show the checkmark when checked */
    .radio input:checked ~ .checkmark:after {
        display: block;
    }
    
    /* Style the checkmark/indicator */
    .radio .checkmark:after {
        left: 12px;
        top: 7px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .ant-layout-content {
        .orders-table {
            .ant-table-tbody {
                font-family: "Montserrat";
            }
            .ant-table-thead {
                background-color: #BFBFBF;
                .ant-table-header-column {
                font-weight: 400;
                color: #AFAFAF;
                }
            }
        }

        
    }
    
    
    
    .orders-table {
        margin-top: 20px;
        border-radius: 15px;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

        overflow-x: auto;
    }
    
    .search-container {
        margin-right: 20px;
        input {
        border: 1.5px solid #BFBFBF;
        }
    }
    
    
    
    .ant-modal-header {
        padding: 0;
    }
    
    
    .ant-modal-body {
        padding: 0;
    }
    
    
    
        .ant-table-tbody {
        tr {
            cursor: pointer;
            }
        .order-status-tag {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 700;
    
            .cancelled {
            border-radius: 50px;
            padding: 10px 25px;
            min-width: 100px;
            color:#A80F1B;
            background-color: #FEF3F3;
            border: 1px solid #F5A2A6;
            }
    
            .active {
            border-radius: 50px;
            padding: 10px 25px;
            min-width: 100px;
            color: #028010;
            background-color: #e6fff1;
            border: 1px solid #78d89f;
            }
    
            .invoiced {
            border-radius: 50px;
            padding: 10px 25px;
            min-width: 100px;
            color: #028010;
            background-color: #e6fff1;
            border: 1px solid #78d89f;
            }
    
            .in-process {
            border-radius: 50px;
            padding: 10px 20px;
            min-width: 100px;
    
            color: #d66f0e;
            background-color: #ffefe0;
            border: 1px solid #ffc590;
            }
        }
        }
}

.btn-primary {
    font-weight: 600;
    padding: 0 30px 0 30px;
    -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.15);

    background-color: #bf914a;
    border-radius: 30px;
    border-color: #bf914a;
    color: #FFFFFF;
    &:hover, &:active, &:focus {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;

    }
    &:disabled, &:disabled:hover {
        background-color: #E5D3B6;
        border-color: #E5D3B6;
        color: #FFFFFF;
    }
}

.btn-default {
color: #bf914a;
border: none;
&:hover {
    color: #bf914a;
    border: none;
    background: transparent;
}
&:disabled, &:disabled:hover {
    background: transparent;
}
}

.btn-small {
height: 42px;
min-width: 150px;
font-size: 16px;
line-height: 22px;
}

.orders-tab {
    .ant-tabs-tab:hover {
        color: #bf914a;
    }

    .ant-tabs-tab-active {
        color: #bf914a;
    }

    .ant-tabs-ink-bar {
        background-color: #bf914a;
    }

    .ant-tabs-tabpane {
        padding-top: 20px;
        overflow-y: scroll;
        height: 50vh;
    }
}

.responses-modal {
    .approved {
        color: #399049;
    }

    .not-approved {
        color: #A80F1B;
    }

    .ant-modal-title {
        font-family: "Montserrat";
        font-size: 1.4rem;
        font-weight: 500;
        padding: 15px 0px 15px 0px;
    }

    .ant-tabs-tab:hover {
        color: #bf914a;
    }

    .ant-tabs-tab-active {
        color: #bf914a;
    }

    .ant-tabs-ink-bar {
        background-color: #bf914a;
    }

    .ant-tabs-tabpane {
        padding-top: 20px;
        overflow-y: scroll;
        height: 50vh;
    }

    // .ant-tabs-tabpane-active {
    //     overflow-y: scroll;
    //     height: 50vh;
    // }

    .recent-responses {
        font-size: 1.1rem;
        font-weight: 500;
    }

    .datepicker-label {
        position: relative;
        top: 10px;
        padding: 0px 10px 0px 30px;
    }

    .datepicker {
        top: 10px;
    }

    .ant-input {
        border: 1.5px solid #BFBFBF;
        color: #BFBFBF;
    }

    h1 {
        padding-top: 30px;
    }

    p {
        font-family: "Open Sans";
        font-size: 0.9rem;
        line-height: 25px;

        .single-notif-date {
            color: #BFBFBF;
        }
    }
}

.order-status-change-modal {
    .ant-modal-title {
        font-family: "Montserrat";
        font-size: 1.4rem;
        font-weight: 500;
        padding: 15px 0px 15px 0px;
    }

    .remarks-input {
        border: 0;
        border-bottom: 1.5px solid #BFBFBF;
        margin-bottom: 5%;
    }

    p {
        font-family: "Montserrat";
        font-size: 1.4rem;
        font-weight: 500;
        padding: 15px 0px 15px 0px;
    }
}

.order-tag-inprocess {
    background-color: #ffefe0 !important;
    border-radius: 50px !important;
    border: 1px solid #ffc590;
    color:#d66f0e !important;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}
  
.order-tag-invoiced {
    background-color: #e6fff1 !important;
    border-radius: 50px !important;
    border: 1px solid #78d89f;
    color:#028010 !important;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}

.order-tag-cancelled {
    background-color: #fef3f3 !important;
    border-radius: 50px !important;
    border: 1px solid #f5a2a6;
    color:#a80f1b !important;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}

.status-radio-filter {
    display: flex;
    h1{
        font-size: 14px;
        margin: 6px 35px;
        color: #6A6A6A;
        text-transform: uppercase;
        font-weight: 400;
    }

    

    .contact-search {
      width:100%;
  
      .contact-sort-by {
         span {
           margin-left: 10px;
           margin-right: 10px;
         }
         .contact-sort-by-input {
          width: 200px;
         }
      }
    }
  }
@import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800');