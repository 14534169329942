.page-title {
    color: #303030;
}

.button-group {
    .btn-small {
        height: 42px;
        // min-width: 200px;
        font-size: 16px;
        line-height: 22px;
    }
    
    .btn-primary {
        border-radius: 20px !important;
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;
    
        &:hover, &:active, &:focus {
            background-color: #bf914a;
            border-color: #bf914a;
            color: #FFFFFF;
    
        }
        &:disabled, &:disabled:hover {
            background-color: #E5D3B6;
            border-color: #E5D3B6;
            color: #FFFFFF;
        }
    }
}

.ant-table-column-title {
    color: #A9A9A9;
    font-weight: normal;
}

.brands-container {
    padding-top: 25px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);


    .search-container {
        margin: 20px;
        width: 25%;
        .ant-input {
            border: 1.5px solid #BFBFBF;
            color: #808080;
        }
    }

    .inactive {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color:#A80F1B;
        background-color: #FEF3F3;
        border: 1px solid #F5A2A6;
    }
    
    .active {
        font-weight: 600;
        border-radius: 50px;
        min-width: 100px;
        color: #028010 !important;
        background-color: #e6fff1 !important;
        border: 1px solid #78d89f !important;
    }
}

.inactive {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color:#A80F1B;
    background-color: #FEF3F3;
    border: 1px solid #F5A2A6;
}

.inactive:hover {
    color:#A80F1B;
    background-color: #FEF3F3;
    border: 1px solid #F5A2A6;
}

.active {
    font-weight: 600;
    border-radius: 50px;
    min-width: 100px;
    color: #028010 !important;
    background-color: #e6fff1 !important;
    border: 1px solid #78d89f !important;
}

.ant-switch-checked {
    background-color: #303030;
}

.btn-secondary {
    color: #2298E7;
    border: 1px solid #2298E7; 
    &:hover {
      color: #2298E7;
      border: 1px solid #2298E7; 
      background: transparent;
    }
    &:disabled, &:disabled:hover {
      background: transparent;
    }
  }

